* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* scroll-behavior: smooth; */
}

body {
  background: var(--color-bg);
  font-family: -apple-system , BlinkMacSystemFont , "Segoe UI" , Roboto , "Helvetica Neue", "PingFang SC", "Noto Sans" , "Noto Sans CJK SC" , "Microsoft YaHei" , "微软雅黑" , sans-serif;
}

a {
  color: unset;
  text-decoration: none;
}


.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 63px 157px;
}
.max_w_1600{
  width: 100%;
  max-width: 1600px;
  margin: auto;
  position: relative;
}
.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 1050px) {
    .section__padding {
      padding: 15px 20px;
    }
}